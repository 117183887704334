<template>
  <div class="product-wrap case-wrap">
    <Header is-active="case" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-case-c"></div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="txt-box">
          工厂焊装、涂装、总装车间设备运行状态由人工监控，设备维修处理人工微信等方式报修，设备报警等 依靠对讲机呼叫，事后进行异常处理数据人工录入，数据真实有效性较差，人工统计，过程复杂，浪费较多。
          信息沟通环节未打通，导致生产成本居高不下。项目主要帮助客户建立数字化工厂，解决企业痛点。
        </div>
        <div class="auto-img mt-5">
          <img src="../../assets/img/programme/factory/pic_g.jpg" alt="案例">
        </div>
        <div class="txt-box mt-5">
          云昇新控将信息技术与管理现代化有机结合，利用工厂工艺建模技术、数据采集技术、实时数据处理技术、管理和实时大数据融合技术等手段帮助客户构建智慧工厂。为用户提供如显示控制、有线/无线的物联网通信、电源隔离、RFID读写卡等方案，帮助用户实现工厂的平台化管理、设备显控、联网、远程监测等功能，助力企业实现工厂数字化。
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>客户价值</h4></div>
        <div class="customized-box advantage-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-hulian"></i></div>
            <p>设备互联</p>
            <p>实时可视化</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-KPI1"></i></div>
            <p>关键KPI</p>
            <p>性能可见性</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-shuju"></i></div>
            <p>数据有效</p>
            <p>的洞察</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-zhineng"></i></div>
            <p>基于云的</p>
            <p>智能分析</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '工厂数字化,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控工厂数字化,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  name: 'Case',
  components: {
    Header,
    Footer
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.case-wrap {
  .banner-case-c {
    background: url("../../assets/img/case/c/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
